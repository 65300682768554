import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Meta title="404: Not found" />
    <div className="container pad">
      <h1 className="xd-h1 mb-4">NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
